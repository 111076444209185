import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/mobsuccess_logo.svg";

const TitleUI = styled.h1`
  margin: 0 0 24px 0;
`;

TitleUI.displayName = "TitleUI";

const HeaderUI = styled.header`
  & > svg {
    color: white;
  }
`;

HeaderUI.displayName = "HeaderUI";

function Header() {
  return (
    <HeaderUI>
      <Logo />
      <TitleUI>Figma Palette Generator</TitleUI>
    </HeaderUI>
  );
}

export default Header;

import React, { useCallback, useState } from "react";
import JSONOutput from "../JSONOutput";
import File from "./File";

function FileBase() {
  const [file, setFile] = useState("");

  const handleChange = useCallback((input) => {
    setFile(input);
  }, []);

  return file ? <JSONOutput file={file} /> : <File onChange={handleChange} />;
}

export default FileBase;

import React, { useCallback, useEffect, useState } from "react";
import JSONOutput from "./JSONOutput";

function JSONOutputBase({ file }) {
  const [palette, setPalette] = useState(null);

  const getPixelColor = useCallback((x, y, imageData) => {
    const index = (y * imageData.width + x) * 4;
    const red = imageData.data[index];
    const green = imageData.data[index + 1];
    const blue = imageData.data[index + 2];
    return `#${(
      (1 << 24) +
      (parseInt(red) << 16) +
      (parseInt(green) << 8) +
      parseInt(blue)
    )
      .toString(16)
      .slice(1)}`;
  }, []);

  const getRangeColors = useCallback(
    (y, imageData) => {
      const colors = [];

      for (let i = 0; i < 11; i++) {
        colors.push(getPixelColor(171 + i * 262, 403 + y * 362, imageData));
      }
      return colors;
    },
    [getPixelColor]
  );

  useEffect(() => {
    const colorsName = [
      "primary",
      "secondary",
      "interface",
      "success",
      "danger",
      "warning",
      "info",
    ];
    const reader = new FileReader();
    const image = new Image();
    let paletteToFill = {};

    image.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      for (let j = 0; j < colorsName.length; j++) {
        let rangeColors = getRangeColors(j, imageData);
        paletteToFill[colorsName[j]] = {
          base: rangeColors[3],
          darker: {
            100: rangeColors[0],
            200: rangeColors[1],
            300: rangeColors[2],
          },
          lighter: {
            100: rangeColors[4],
            200: rangeColors[5],
            300: rangeColors[6],
            400: rangeColors[7],
            500: rangeColors[8],
            600: rangeColors[9],
            700: rangeColors[10],
          },
        };
      }
      setPalette(paletteToFill);
    };

    reader.onloadend = function () {
      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  }, [file, getRangeColors]);

  return (
    <JSONOutput content={palette ? JSON.stringify(palette) : `Converting...`} />
  );
}

export default JSONOutputBase;

import React from "react";
import PropTypes from "prop-types";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";

const FileUI = styled.div``;

FileUI.displayName = "FileUI";

function File({ onChange }) {
  return (
    <FileUI>
      <FileUploader handleChange={onChange} types={["PNG"]} />
    </FileUI>
  );
}

File.propTypes = {
  onChange: PropTypes.func,
};

export default File;
